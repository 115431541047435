import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

const navigationContents = document.querySelector<HTMLDivElement>("header.navigation .contents");
const hamburgerMenu = document.querySelector<HTMLSpanElement>(".hamburger");
const anchors = document.querySelectorAll<HTMLAnchorElement>("a[name]");

async function setupHamburgerMenu() {
  hamburgerMenu.addEventListener("click", () => {
    if (navigationContents.classList.contains("shown")) {
      navigationContents.classList.remove("shown");
    } else {
      navigationContents.classList.add("shown");
    }
  });
}

async function setupTippy() {
  for (let anchor of anchors) {
    tippy(anchor, {
      content: anchor.getAttribute("name"),
      placement: "bottom",
      theme: "core"
    });
  }
}

setupTippy();
setupHamburgerMenu();
